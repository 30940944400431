<template>
  <div v-if="$role(['director', 'head_operation'], $store.state.user.role)">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4">
        <v-card :class="$role(['head_operation'], $store.state.user.role) ? 'primary' : 'grey'" class="d-flex align-center justify-center px-4 fill-width caption font-weight-bold py-2" dark outlined @click.prevent="$role(['head_operation'], $store.state.user.role) ? ACCOUNT_SHOW() : false">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Account
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-select
            v-model.number="storeUnit"
            :disabled="$role(['operation_unit', $store.state.user.role]) ? true : false"
            :items="[{ name: 'All', id: 0 }].concat($store.state.storeData)"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            label="Operations Unit"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-8 white">
        <div class="px-0 px-sm-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search (Account Name, Email, Phone)"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [8, 15, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              #{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.name }} - {{ item.phone }}
              </div>
              <div v-if="item.role === 'mechanic'">
                <v-btn outlined color="primary" x-small class="caption text-capitalize rounded-pill" @click.prevent="ACCOUNTS_ATTENDANCE_GET(item)">
                  Attendance
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:[`item.store_name`]="{ item }">
            <div>
              {{ item.store_name }}
            </div>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <div>
              {{ item.role }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey'" outlined dark small>
                {{ parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['head_operation'], $store.state.user.role)" :color="$role(['head_operation'], $store.state.user.role) ? 'orange' : false" depressed small dark class="rounded text-capitalize" @click.prevent="$role(['head_operation'], $store.state.user.role) ? ACCOUNT_SHOW(item) : false">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAccount.show"
      persistent
      scrollable
      max-width="450"
    >
      <v-card v-if="dialogAccount.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogAccount.data.id ? 'Update Account' : 'Create New Account' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 90vh;">
          <v-text-field
            v-model="dialogAccount.data.name"
            outlined
            dense
            hide-details
            label="Nama"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogAccount.data.email"
            outlined
            dense
            hide-details
            label="Email"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogAccount.data.phone"
            outlined
            dense
            hide-details
            label="No.HP / Whatsapp"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-text-field
            v-if="!parseInt(dialogAccount.data.id)"
            v-model="dialogAccount.data.password"
            outlined
            dense
            hide-details
            label="Password Sementara"
            type="password"
            class="mb-4 rounded-0"
          />
          <v-select
            v-model="dialogAccount.data.role"
            :items="$store.state.user.role === 'root' || $store.state.user.role === 'admin' || $store.state.user.role === 'head_operation' ? [
              { name: 'Super Admin', value: 'admin' },
              { name: 'Director', value: 'director' },
              { name: 'Service Advisor', value: 'service_advisor' },
              { name: 'Head Operation', value: 'head_operation' },
              { name: 'Logistic', value: 'logistic' },
              { name: 'Operation Unit', value: 'operation_unit' },
              { name: 'Mechanic', value: 'mechanic' }
            ] : [
              { name: 'Logistic', value: 'logistic' },
              { name: 'Mechanic', value: 'mechanic' }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Account Role"
          />
          <v-select
            v-if="dialogAccount.data.role !== 'admin' && dialogAccount.data.role !== 'director' && dialogAccount.data.role !== 'service_advisor' && dialogAccount.data.role !== 'head_operation' && dialogAccount.data.role !== 'logistic'"
            v-model.number="dialogAccount.data.store"
            :items="$store.state.storeData.filter(r => $role([], $store.state.user.role) ? true : (parseInt(r.id) === parseInt($store.state.user.store.id)))"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Unit Location"
          />
          <v-select
            v-model.number="dialogAccount.data.status"
            :items="[
              { name: 'Active', value: 1 },
              { name: 'Not Active', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Account Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogAccount.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="ACCOUNT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAttendance.show"
      persistent
      scrollable
      max-width="450"
    >
      <v-card v-if="dialogAttendance.detail">
        <v-card-title class="body-1 justify-center">
          {{ 'Attendance: ' + dialogAttendance.detail.name }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 90vh;">
          <div v-for="(a, iA) in dialogAttendance.data" :key="'attendance-' + iA" class="pt-2 my-4 rounded-lg grey lighten-2" style="overflow: hidden;">
            <div class="d-flex align-center subtitle-1 pl-4 pb-1">
              <div class="d-flex align-center">
                <v-icon class="mr-1" small>
                  mdi-calendar
                </v-icon>
                {{ $localDT(a.dt, 'datelocal') }}
              </div>
            </div>
            <v-row class="grey lighten-4 py-2" no-gutters>
              <v-col cols="6" class="d-flex flex-column align-center justify-center">
                <strong class="primary--text">CLOCK IN</strong>
                <div class="text-h5 pb-1 d-flex fill-width align-center justify-center caption font-weight-bold">
                  {{ a.clockin ? $localDT(a.clockin, 'time') : '-' }}
                </div>
                <div class="d-flex align-center justify-center fill-width">
                  <div class="d-flex align-center mr-1 c-pointer rounded-pill white--text caption indigo px-2" @click.prevent="GoLink((a.clockin_lat && a.clockin_lng) ? 'https://www.google.com/maps/search/?api=1&query=' + a.clockin_lat + ',' + a.clockin_lng : '')">
                    <v-icon class="mr-1" color="white" small>
                      mdi-map-marker
                    </v-icon>
                    Location
                  </div>
                  <div class="d-flex align-center mr-1 c-pointer rounded-pill white--text caption indigo px-2" @click.prevent="GoLink(a.clockin_selfie || '')">
                    <v-icon class="mr-1" color="white" small>
                      mdi-image
                    </v-icon>
                    Image
                  </div>
                </div>
              </v-col>
              <v-col cols="6" class="d-flex flex-column align-center justify-center">
                <strong class="red--text">CLOCK OUT</strong>
                <div class="text-h5 pb-1 d-flex fill-width align-center justify-center caption font-weight-bold">
                  {{ a.clockout ? $localDT(a.clockout, 'time') : '-' }}
                </div>
                <div class="d-flex align-center justify-center fill-width">
                  <div class="d-flex align-center mr-1 c-pointer rounded-pill white--text caption indigo px-2" @click.prevent="GoLink((a.clockout_lat && a.clockout_lng) ? 'https://www.google.com/maps/search/?api=1&query=' + a.clockout_lat + ',' + a.clockout_lng : '')">
                    <v-icon class="mr-1" color="white" small>
                      mdi-map-marker
                    </v-icon>
                    Location
                  </div>
                  <div class="d-flex align-center mr-1 c-pointer rounded-pill white--text caption indigo px-2" @click.prevent="GoLink(a.clockout_selfie || '')">
                    <v-icon class="mr-1" color="white" small>
                      mdi-image
                    </v-icon>
                    Image
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize font-weight-bold"
            @click="dialogAttendance.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
export default {
  name: 'AccountManager',
  data: () => ({
    storeUnit: 0,
    headers: [
      {
        text: 'ACCOUNT ID',
        value: 'id',
        sortable: false
      },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Unit', value: 'store_name', sortable: false },
      { text: 'Role', value: 'role', sortable: false },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogAccount: {
      show: false,
      data: null
    },
    dialogAttendance: {
      show: false,
      data: [],
      total: 0,
      detail: null
    }
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.ACCOUNTS_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.ACCOUNTS_GET(this.tableSearch)
      }
    },
    storeUnit () {
      this.ACCOUNTS_GET(this.tableSearch)
    }
  },
  mounted () {
    this.ACCOUNTS_GET()
  },
  methods: {
    GoLink (link) {
      if (link) {
        const a = document.createElement('a')
        a.setAttribute('href', link)
        a.setAttribute('target', '_blank')
        a.click()
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Url tidak valid!' })
      }
    },
    ACCOUNTS_ATTENDANCE_GET (account) {
      this.dialogAttendance.data = []
      this.dialogAttendance.total = 0
      this.dialogAttendance.detail = Object.assign({}, account)
      // {{ https://www.google.com/maps/@-3.598702777777778,136.30274722222222,1077m/data=!3m1!1e3 }}
      this.$store.dispatch('account/ACCOUNTS_ATTENDANCE_GET', '?user=' + (parseInt(account.id) || 0))
        .then((res) => {
          if (res.status) {
            const newD = []
            const d = res.data.data.data || []
            for (let e = 0; e < d.length; e++) {
              const existIndex = newD.findIndex(r => r.dt === this.$localDT(d[e].created, 'datedefault'))
              if (existIndex > -1) {
                if (d[e].type === 'clockout') {
                  newD[existIndex].clockout = d[e].created
                  newD[existIndex].clockout_lat = d[e].poslat
                  newD[existIndex].clockout_lng = d[e].poslang
                  newD[existIndex].clockout_selfie = d[e].selfie || null
                } else {
                  newD[existIndex].clockin = d[e].created
                  newD[existIndex].clockin_lat = d[e].poslat || 0
                  newD[existIndex].clockin_lng = d[e].poslang || 0
                  newD[existIndex].clockin_selfie = d[e].selfie || null
                }
              } else {
                newD.push({
                  dt: this.$localDT(d[e].created, 'datedefault'),
                  clockin: d[e].type === 'clockout' ? '' : d[e].created,
                  clockin_lat: d[e].type === 'clockout' ? '' : d[e].poslat,
                  clockin_lng: d[e].type === 'clockout' ? '' : d[e].poslang,
                  clockin_selfie: d[e].type === 'clockout' ? null : d[e].selfie,
                  clockout: d[e].type === 'clockout' ? d[e].created : '',
                  clockout_lat: d[e].type === 'clockout' ? d[e].poslat : 0,
                  clockout_lng: d[e].type === 'clockout' ? d[e].poslang : 0,
                  clockout_selfie: d[e].type === 'clockout' ? d[e].selfie : null
                })
              }
            }
            this.dialogAttendance.data = newD
            this.dialogAttendance.total = parseInt(res.data.data.total) || 0
            this.dialogAttendance.show = true
          }
        })
    },
    ACCOUNT_SHOW (account) {
      if (account) {
        this.dialogAccount.data = Object.assign({}, account)
      } else {
        this.dialogAccount.data = {
          id: null,
          name: '',
          email: '',
          phone: '',
          password: '',
          store: 0,
          status: 1,
          role: ''
        }
      }
      this.dialogAccount.show = true
    },
    ACCOUNTS_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const store = parseInt(this.storeUnit) ? ('&store=' + this.storeUnit) : ''
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + store + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('account/ACCOUNTS_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data.data
          this.tableTotal = parseInt(res.data.data.total) || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    ACCOUNT_PROCESS () {
      const processedAccount = Object.assign({}, this.dialogAccount.data)
      if (!processedAccount.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (!this.$validateEmail(processedAccount.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email tidak valid!' })
        return false
      }
      if (!this.$validatePhone(processedAccount.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No HP tidak valid!' })
        return false
      }
      processedAccount.phone = this.$reformatPhone(processedAccount.phone)
      if (this.$store.state.user.role !== 'root' && this.$store.state.user.role !== 'admin') {
        if (processedAccount.role === 'root' || processedAccount.role === 'admin' || processedAccount.role === 'director' || processedAccount.role === 'service_advisor' || processedAccount.role === 'head_operation') {
          this.$store.dispatch('TOAST', { show: true, message: 'Hanya super admin yang bisa melakukan otorisasi role ini!' })
          return false
        }
      }
      if (processedAccount.role === 'admin' || processedAccount.role === 'director' || processedAccount.role === 'service_advisor' || processedAccount.role === 'head_operation' || processedAccount.role === 'logistic') {
        processedAccount.store = 0
      } else if (!parseInt(processedAccount.store)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Store Location belum dipilih!' })
        return false
      }
      this.$store.dispatch('account/ACCOUNT_PROCESS', processedAccount)
        .then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              this.ACCOUNTS_GET(this.tableSearch)
              this.dialogAccount.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedAccount.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    }
  }
}
</script>
